<template>
	<div id="order" class="pink lighten-5">
		<h2 class="text-titulo section-title">{{ $store.state.titulofix }}</h2>
		<v-spacer class="my-3"></v-spacer>
		<v-container fluid>
			<v-row dense>
				<v-col cols="12" md="auto">
					<v-btn
						@click="abiertos = []"
						color="grey darken-3"
						class="my-2"
						large
						dark>
						<v-icon left>mdi-close-circle-outline</v-icon>Close Details
					</v-btn>
				</v-col>
				<v-col cols="12" md="2">
					<v-text-field 
						label="Search Order" 
						append-icon="mdi-close" 
						prepend-icon="mdi-magnify"
						v-model="buscador"
						v-on:keyup.enter="fnLoader('search', buscador)"
						@click:append="fnClearSearch">
					</v-text-field>
				</v-col>
				<v-col cols="12" md="auto">
					<v-btn
						@click="fnClearSearch"
						color="grey darken-3"
						class="my-2"
						large
						dark>
						<v-icon left>mdi-reload</v-icon>Reset
					</v-btn>
				</v-col>
				<v-col cols="12" md="auto">
					<v-btn
						color="red darken-3"
						class="my-2"
						large
						dark
						@click="fnUpdDate">
						<v-icon left>mdi-lock</v-icon>{{ fechalimite }}
					</v-btn>
				</v-col>
			</v-row>
		</v-container>
		<!-- tabla -->
		<v-data-table
			:headers="cabecera" 
			:items="listado" 
			:item-class="fnLineclass"
			class="elevation-1" 
			disable-sort
			:loading="stLoading"
			:items-per-page="limite"
			:fixed-header="true"
			:hide-default-footer="true"
			:show-expand="true"
			:expanded="abiertos"
			@item-expanded="fnDetalle">

			<template v-slot:item.title="{ item }">
				{{ item.title }} <v-icon v-if="item.chkprc" small color="orange darken-4">mdi-alert-circle-outline</v-icon>
			</template>
			<template v-slot:item.bill="{ item }">
				{{ item.name }} (<a text @click="fnLoader('mail', item.email)">{{ item.email }}</a>) <v-badge v-if="item.cantidad > 1" color="light-blue darken-3" class="float-right" inline bordered :content="item.cantidad"></v-badge>
			</template>
			<template v-slot:item.ship="{ item }">
				{{ item.shippingTo }} <v-icon v-if="item.streetChk" small color="white" class="float-right red pa-1">mdi-link-off</v-icon>
			</template>
			<template v-slot:item.gift="{ item }">
				<a @click="fnLoader('gift', item.gift)">{{ item.gift }}</a>
			</template>
			<template v-slot:item.stats="{ item }">
				<div class="text-center text-no-wrap" width="100%" v-for="riesgo in item.risk" :key="riesgo">
					<v-chip v-if="riesgo == 'AVS Match'" x-small class="ma-1" color="success">{{ riesgo }}</v-chip>
					<v-chip v-if="riesgo == 'AVS Partial Match'" x-small class="ma-1" color="warning">{{ riesgo }}</v-chip>
					<v-chip v-if="riesgo == 'AVS No Match'" x-small class="ma-1" color="error">{{ riesgo }}</v-chip>
					<v-chip v-if="riesgo == 'AVS Unavailable'" x-small class="ma-1" color="info">{{ riesgo }}</v-chip>
					<v-chip v-if="riesgo == 'Not Supported'" x-small class="ma-1" color="error">{{ riesgo }}</v-chip>
					<v-chip v-if="riesgo == 'Paypal Managed'" x-small class="ma-1" color="info">{{ riesgo }}</v-chip>
					<v-chip v-if="riesgo == 'CVV2 Match'" x-small class="ma-1" color="success">{{ riesgo }}</v-chip>
					<v-chip v-if="riesgo == 'CVV2 Issues'" x-small class="ma-1" color="warning">{{ riesgo }}</v-chip>
				</div>
			</template>
			<template v-slot:item.estado="{ item }">
				<v-icon v-if="item.status == 'FULFILLED'" color="green" large>mdi-checkbox-marked-circle</v-icon>
				<v-icon v-else color="red" large>mdi-checkbox-blank-circle-outline</v-icon>
			</template>
			<template v-slot:item.ip="{ item }">
				<a @click="fnFilter('ip', item.ip)">{{ item.ip }}</a>
			</template>
			<template v-slot:item.total="{ item }">
				<strong v-if="parseFloat(item.total) >= 150" class="red--text">${{ item.total }}</strong>
				<strong v-else>${{ item.total }}</strong>
			</template>
			<template v-slot:item.fecha="{ item }">
				{{ fnTimeZone(item.createdAt, 'S') }}
			</template>
			<template v-slot:item.actions="{ item } ">
				<v-btn-toggle v-model="item.flag" color="blue-grey darken-3" tile borderless mandatory>
					<v-btn :value="0" small @click="fnColorChn(item.id, '0')">
						<v-icon color="grey lighten-1">mdi-minus-circle</v-icon>
					</v-btn>
					<v-btn :value="1" small @click="fnColorChn(item.id, '1')">
						<v-icon color="green">mdi-information</v-icon>
					</v-btn>
					<v-btn :value="2" small @click="fnColorChn(item.id, '2')">
						<v-icon color="red">mdi-close-circle</v-icon>
					</v-btn>
				</v-btn-toggle>
			</template>

			<template v-slot:expanded-item="{ headers, item }">
				<td :colspan="headers.length" style="padding:0;">
					<v-card class="pa-3 grey darken-2" flat>
						<v-sheet
							color="grey lighten-4"
							class="px-3 pt-3 pb-3"
							v-if="preload && curItem == item.id"
							>
							<v-skeleton-loader
							class="mx-auto"
							max-width="100%"
							type="table-row-divider"
							></v-skeleton-loader>
						</v-sheet>
						<div class="d-flex" v-else>
							<v-simple-table dense style="width: 35%">
								<template v-slot:default>
									<thead class="grey lighten-1">
										<tr>
											<th><v-icon dark left>mdi-credit-card</v-icon>Billing</th>
											<th><v-icon dark left>mdi-truck</v-icon>Shipping</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td><strong>{{ item.details.billing.name }} {{ item.details.billing.lastN }}</strong></td>
											<td><strong>{{ item.details.shipping.name }} {{ item.details.shipping.lastN }}</strong></td>
										</tr>
										<tr>
											<td>{{ item.details.billing.address1 }}<br />{{ item.details.billing.address2 }}</td>
											<td>
												<v-dialog v-model="buscadir" persistent max-width="410">
													<template v-slot:activator="{ on, attrs }">
														<v-btn v-bind="attrs" text v-on="on" @click="temporal = item.details.shipping.address1" class="addressbtn">
															<v-icon left>mdi-magnify</v-icon>{{ item.details.shipping.address1 }}
														</v-btn>
													</template>
													<v-card>
														<v-card-title class="headline">SEARCH ADDRESS</v-card-title>
														<v-card-text>
															<v-text-field label="Address" v-model="temporal"></v-text-field>
														</v-card-text>
														<v-divider></v-divider>
														<v-card-actions>
															<v-btn color="red darken-1" text @click="buscadir = false">Close</v-btn>
															<v-btn color="green darken-1" text @click="fnFilter('address', temporal)">Search</v-btn>
														</v-card-actions>
													</v-card>
												</v-dialog>
												<br />
												{{ item.details.shipping.address2 }}</td>
										</tr>
										<tr>
											<td>{{ item.details.billing.city }}</td>
											<td>{{ item.details.shipping.city }}</td>
										</tr>
										<tr>
											<td>{{ item.details.billing.zip }}, {{ item.details.billing.province }}</td>
											<td>{{ item.details.shipping.zip }}, {{ item.details.shipping.province }}</td>
										</tr>
										<tr>
											<td>{{ item.details.billing.country }}</td>
											<td>{{ item.details.shipping.country }}</td>
										</tr>
									</tbody>
									<tfoot>
										<tr class="white">
											<td>
												<h3 class="h6">Shipping Carrier</h3>
												<p>{{ item.details.carrier }}</p>
											</td>
											<td>
												<v-chip class="ma-2" color="deep-orange darken-3" text-color="white" v-if="item.details.discount">
													<v-icon left>mdi-gift</v-icon>{{ item.details.discount }}
												</v-chip>
											</td>
										</tr>
									</tfoot>
								</template>
							</v-simple-table>
							<v-simple-table style="width: 65%" class="mb-auto mx-1" dense>
								<template v-slot:default>
									<thead class="grey lighten-1">
										<tr>
											<th>sku</th>
											<th>product</th>
											<th>variant</th>
											<th>quantity</th>
											<th>price</th>
											<th>subtotal</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="subitem in item.details.variants" :key="subitem.sku">
											<td>{{ subitem.sku }}</td>
											<td>{{ subitem.title }}</td>
											<td>{{ subitem.variant }}</td>
											<td class="text-center">{{ subitem.quantity }}</td>
											<td class="text-right">${{ subitem.price }}</td>
											<td class="text-right">${{ fnCalcPrice(subitem.price, subitem.quantity) }}</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</div>
					</v-card>
				</td>
			</template>

			<template v-slot:footer>
				<v-btn 
					block 
					text 
					x-large 
					color="deep-orange darken-3" 
					@click="fnChangePg('next')" 
					:disabled="stNext" 
					:loading="stLoading"><v-icon left>mdi-chevron-double-down</v-icon>Load more<v-icon right>mdi-chevron-double-down</v-icon></v-btn>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import axios from "axios";
import { DateTime } from "luxon";

export default {
	name: 'SEOrders',
	metaInfo: {
		title: 'SE Orders'
	},
	data() {
		return {
			cabecera: [
				{ text: 'Order', value: 'title', cellClass: 'text-no-wrap' },
				{ text: 'Billing to', value: 'bill' },
				{ text: 'Shipping to', value: 'ship' },
				{ text: 'Gift', value: 'gift' },
				{ text: 'Payment stat', value: 'stats' },
				{ text: 'Payment', value: 'gateway' },
				{ text: 'IP', value: 'ip' },
				{ text: 'Status', value: 'estado' },
				{ text: 'Total', value: 'total', align: 'end' },
				{ text: 'Purchased date', value: 'fecha' },
				{ text: 'Flag', value: 'actions' }
			],
			listado: [],
			abiertos: [],
			stLoading: false,
			limite: -1,
			txtOrder: '',
			fechalimite: '???',
			stNext: false,
			preload: true,
			curItem: null,
			buscador: null,
			buscadir: false,
		}
	},
	mounted () {
		axios.post('/se-getdate')
				.catch(error => console.log(error))
				.then(response => {
					this.fechalimite = this.fnTimeZone(response.data, 'M')
				});
		this.$store.state.titulofix = 'Orders from STEVEN'
		this.fnLoader();
	},
	methods: {
		fnClearSearch() {
			this.listado = []
			this.abiertos = []
			this.buscador = null
			this.cursor = null
			this.fnLoader()
		},
		fnLineclass(val) {
			let bgColor = ''
			switch(val.flag) {
				case 1:
					bgColor = 'green accent-1'
					break
				case 2:
					bgColor = 'red lighten-4'
					break
				default:
					bgColor = ''
			}
			return bgColor
		},
		fnDetalle({item, value}) {
			if(value) {
				this.preload = true
				this.curItem = item.id
				axios.post('/se-detailorder', { id: item.id })
				.catch(error => console.log(error))
				.then(response => { 
					item.details = response.data
					this.preload = false
				})
			}
		},
		fnLoader(campo = null, valor = null) {
			this.stLoading = true;
			if(campo != null) this.cursor = null
			axios.post('/se-getorders', { cursor: this.cursor, campo: campo, valor: valor })
				.catch(() => {
					this.$store.state.mensaje = 'Error: server side exception. Wait a moment and try again.'
					this.$store.state.estado = 'error'
					this.$store.state.snackme = true
					this.$errorsnd()
				})
				.then(response => {
					if(this.cursor != null) {
						this.listado = this.listado.concat(response.data.datos)	
					}else{
						this.abiertos = []
						this.listado = response.data.datos
					}
					this.stNext = !response.data.hasNext
					this.stLoading = false
				});
		},
		fnFilter(campo = null, valor = null) {
			this.stLoading = true;
			axios.post('/se-getfilter', { campo: campo, valor: valor })
				.then(response => {
					if(campo == null) {
						this.listado = this.listado.concat(response.data.datos)	
					}else{
						this.abiertos = []
						this.listado = response.data.datos
					}
					this.stNext = !response.data.hasNext
					this.stLoading = false
					this.buscadir = false
				});
		},
		fnUpdDate() {
			this.stfecha = true
			axios.post('/se-updatedate')
				.then(response => {
					this.fechalimite = this.fnTimeZone(response.data)
					this.stfecha = false
					this.$successsnd()
				})
		},
		fnColorChn(id, flag) {
			axios.post('/se-ordersflagup', { id: id, flag: flag })
				.then(response => {
					if(response.data) {
						this.snack = true
						this.snackColor = 'success'
						this.snackText = 'This order status has change.'
						this.$successsnd()
					}else{
						this.snack = true
						this.snackColor = 'error'
						this.snackText = 'Error: while writing into database...'
						this.$errorsnd()
					}
				})
		},
		fnChangePg() {
			this.cursor = this.listado[this.listado.length - 1].cursor;
			this.fnLoader();
		},
		fnCalcPrice(price, qty) {
			return (parseFloat(price) * parseInt(qty)).toFixed(2);
		},
		fnTimeZone(val, tipo) {
			const fecha = DateTime.fromISO(val, { zone: "America/New_York" })
			let valor = ''
			if(tipo == 'S') {
				valor = fecha.toLocaleString(DateTime.DATETIME_SHORT)
			}else{
				valor = fecha.toLocaleString(DateTime.DATETIME_MED)
			}
			return valor
		}
	}
}
</script>